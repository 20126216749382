:root {
  --orange: #ea6016;
  --light-orange: #ff8800;
  --orange-1: #f19737;
  --black: #141414;
  --black2: #242424;
  --black3: #1f1f1f;
  --light-black: #353434;
  --black5: #1d1d1d;
  --black6: rgba(24, 24, 24, 0.9);
  --black7: #3a1500;
  --black8: rgba(0, 0, 0, 0.3);
  --black9: rgba(53, 52, 52, 0.5);
  --black10: #2b2b2b;
  --grey: #4b4b4b;
  --light-grey: #808080;
  --grey3: #2c2c2c;
  --grey-30: #303030;
  --grey-96: #969696;
  --grey-3: #343434;
  --grey-4: #bcbcbc;
  --grey-5: #707070;
  --grey-6: #f8f8f8;
  --grey-7: #403732;
  --grey-8: #292929;
  --grey-9: #c7c7c7;
  --grey-10: #f0f0f0;
  --gery-11: #c6c6c6;
  --grey-12: #fafafa12;
  --white: #ffffff;
  --dark: #000;
  --white-rgb: 255 255 255;
  --yellow: #ffd300;
  --yellow-2: #dbbe76;
  --white-1: #c9c9c9;
  --white-2: #a5a5a5;
  --white-op-1: rgb(var(--white-rgb) / 0.1);
  --sliver: #bcbcbc;
  --blue: #0079ff;
  --blue-5: #2eaec1;
  --blue-6: #09ccc7;
  --blue-7: #06afc3;
  --light-blue: #00a7ff;
  --light-blue-2: #06afc3;
  --light-blue-3: #02ccc5;
  --light-blue-4: #02a9c4;
  --light-blue-5: #0d8f9f;
  --red: #ea3c2a;
  --blue-1: rgba(9, 204, 199, 0.3);
  --live-livestreamer: #00b7ff;
  --background_color: var(--black);
}

html,
body {
  padding: 0;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
  Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;

  background-color: var(--background_color);
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}

.navbar {
  border-bottom: solid 1px #EBEBEB;
}
.bottom-tab-nav {
  border-top: solid 1px #EBEBEB;
}
.nav-link,
.bottom-nav-link {
  color: #55575b;
}
.bottom-nav-link.active {
  color: #922c88;
}
.bottom-tab-label {
  font-size: 12px;
}
.section-title {
  position: relative;
  padding-left: 0.5rem;
}
.section-title:after {
  position: absolute;
  content: '';
  width: 4px;
  height: 21px;
  background: linear-gradient(180deg,var(--heading_custom_border_color_1,#f80),var(--heading_custom_border_color_2,#ea3c2a));
  border-radius: 1rem;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
}
.fixed-bottom {
  background: rgb(36,36,36);
}
.bottom-tab-label {
  font-size: 18px;
}
.link {
  cursor: pointer;
}
.vertical-nav {
  height: 100vh;

  transition: all 0.4s;

  display: flex;

  background: #353434;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  justify-content: flex-start;
  position: fixed;
  top: 0;
  left: 0;
  width: 280px;
  flex-wrap: nowrap;
}
.vertical-nav .nav {
  border-bottom: none;
}
.vertical-nav .listview {
  width: 100%;
}
#content {
  flex: 1;
  margin-left: 280px;
}
@media (prefers-color-scheme: dark) {
  html {
    color-scheme: dark;
  }
  body {
    color: white;
    background: black;
  }
}
